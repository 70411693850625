import React, { Component } from 'react'

import Link from '../utils/link'

class PagesRepeaterDouble extends Component {

  state = {
    isMobile: false
  }

  throttledHandleWindowResize = () => {
    this.setState({ isMobile: window.innerWidth < 900 })
  }

  componentDidMount() {
    this.throttledHandleWindowResize()
    window.addEventListener('resize', this.throttledHandleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledHandleWindowResize);
  }


  _renderDetails = (element, j) => {
    return (
      <div className="details" key={j}>
        { element.title && <h3>{ element.title }</h3> }
        <p>{ element.content }</p>
        <Link className='btn btn--fancy' to={ 'tel:' + element.buttonText}><span>{ element.buttonText }</span></Link>
      </div>
      )
  }

  _renderPages = (el, i) => {
    let imageSrc = el.pictureBlock.localFile.childImageSharp.original.src
    let { isMobile } = this.state

    return (
      <div className='full-section' key={i}>
        <div className='repeater__item repeater__item--double'>
          <picture>
            <video autoPlay muted playsInline loop src={el.mobileVideo && isMobile ? el.mobileVideo : el.video }  poster={imageSrc} />
          </picture>
          <div className='content'>
            <div className='content__inner'>
              { el.tagline && <h5>{ el.tagline }</h5> }
              { el.detailsRepeater.map(this._renderDetails) }
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { repeaterItem } = this.props

    return (
      <>
        { repeaterItem.map(this._renderPages) }
      </>
    )
  }
}


export default PagesRepeaterDouble
