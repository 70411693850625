import React, { Component } from 'react'

import Link from '../utils/link'

import Logo from '../assets/images/logo.svg'

class Footer extends Component {

  render() {
    return (
      <>
        <section className='footer fp-auto-height'>
          <div className='footer__inner'>
            <Link to='/'><img className="logo" src={Logo} alt='Robotface' /></Link>
            <p>It's the details that make perfection, but perfection is no small detail </p>
            <ul>
              <li>Contact. <Link to='tel:+61421176251'>+61 421 176 251</Link></li>
              <li>Location. <Link to='https://www.google.com/maps/place/111+Salmon+St,+Port+Melbourne+VIC+3207'>111 Salmon Street, Port Melbourne, Victoria, 3207</Link></li>
            </ul>
          </div>
        </section>
      </>
    )
  }
}

export default Footer
