import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Fade from 'react-reveal/Fade'
import Slider from 'react-slick'

import Next from '../assets/images/next.svg'

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? 'modal active' : 'modal';
  return (
    <div className={showHideClassName}>
      <section className='modal__wrapper'>
        {children}
        <button className='modal__close' onClick={handleClose} />
      </section>
    </div>
  )
}

function NextArrow(props) {
  let { onClick, style, className } = props
  let attr = { onClick, style, className}
  return (
    <span {...attr}><img src={Next} alt='Next project' /></span>
  )
}

class ProjectSlider extends Component {
  static defaultProps = {
    heading: '',
    slides: [],
    title: '',
    videoUrl: '',
    info: '',
  }

  settings = {
    infinite: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    cssEase: 'ease',
    // swipe: true,
    // swipeToSlide: true,
    // touchThreshold: 1,
    touchMove: true,
    pauseOnHover: true,
    pauseOnFocus: true,
    pauseOnDotsHover: true,
    focusOnSelect: true,
    prevArrow: <NextArrow />,
    nextArrow: <NextArrow />,
    beforeChange: (current, next) => this.setState({ itemActive: next }),
    swipe: () => { this.slider.slickPause() },
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2.25,
          slidesToScroll: 2,
        }
      },
    ]
  }

  renderModal = (i) => {
    const { slides } = this.props
    let current = slides[i]
    let source = current && current.picture.localFile.childImageSharp.original.src
    let video = this._renderVideo(current.videoUrl)

    return (
      <>
        { video || <img src={source} alt={current.picture.altText} /> }
        <div className="information">
          <div className="details" dangerouslySetInnerHTML={{__html: current.info }} />
        </div>
      </>
    )
  }

  _renderVideo(video) {
    if (video.indexOf('iframe') !== -1) {
      return <div dangerouslySetInnerHTML={{__html: video }} />
    }
    if (video.indexOf('vimeo') !== -1) {
      video = video.split('vimeo.com/')[1]
      let videoArray = [video]
      let hidden = ''
      let url = ''
      if (video.indexOf('/') !== -1) { 
        videoArray = video.split('/')
        hidden = videoArray[1]
      }
      url = `https://player.vimeo.com/video/${videoArray[0]}?autoplay=1&loop=1&autopause=0`
      if (hidden !== '') url = `https://player.vimeo.com/video/${videoArray[0]}?h=${hidden}&autoplay=1&loop=1&autopause=0`
      return <iframe src={url} frameBorder="0" allow="autoplay; fullscreen" allow='autoplay'></iframe>
    }
    return null
  }

  render() {
    const { heading, slides } = this.props

    return (
      <>
        <div className='full-section full-section--beige'>
          <section className='project-slider'>
          <div className="project-slider_innerdiv">
            <div className='project-slider__inner'>
              <Fade>
                <h3>{ heading }</h3>
                <Slider className='slider' ref={slider => (this.slider = slider)} {...this.settings}>
                  { slides && slides.map((el, i) => {
                    let props = {
                      className: `slider__item`,
                      ref: `q_${i}`,
                      onClick: () => this.props.setModal(this.renderModal(i))
                    }
                    return (
                      <div {...props} key={i}>
                        <img src={el.picture?.localFile?.childImageSharp.original.src} alt={el.picture?.altText}/>
                        <p>{el.title}</p>
                      </div>
                    )
                  })
                  }
                </Slider>
              </Fade>
            </div>
            </div>
          </section>

        </div>
      </>
    )
  }
}

export default ProjectSlider
