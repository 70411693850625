import React, { Component } from 'react'

class VideoBanner extends Component {

  static defaultProps = {
    video: null,
    mobile_video: null,
    poster: null,
    title: '',
    content: '',
  }

  state = {
    isMobile: false
  }

  throttledHandleWindowResize = () => {
    this.setState({ isMobile: window.innerWidth < 900 })
  }

  componentDidMount() {
    this.throttledHandleWindowResize()
    window.addEventListener('resize', this.throttledHandleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledHandleWindowResize);
  }

  render() {
    const { title, content, video, poster, mobileVideo, mobilePoster } = this.props
    let { isMobile } = this.state

    let source = poster && poster.localFile && poster.localFile.childImageSharp && poster.localFile.childImageSharp.original.src
    if (isMobile) source = mobilePoster && mobilePoster.localFile && mobilePoster.localFile.childImageSharp && mobilePoster.localFile.childImageSharp.original.src

    return (
      <div className='full-section'>
        <section className='video-banner'>
          <video ref='video' autoPlay className='video-player' muted playsInline loop src={mobileVideo && isMobile ? mobileVideo : video } poster={source} data-keepplaying><track></track></video>
          <div className='video-banner__inner'>
            <div className='content'>
              { title && <h1>{ title }</h1> }
              <p dangerouslySetInnerHTML={{__html: content }}/>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default VideoBanner
