import React from 'react'

import VideoBanner from '../components/video-banner'
import PagesRepeater from '../components/pages-repeater'
import ProjectSlider from '../components/project-slider'
import Banner from '../components/banner'
import ContactForm from '../components/contact-form'
import ContactMap from '../components/contact-map'
import ProjectGallery from '../components/project-gallery'
import BannerTitle from '../components/banner-title'
import Features from '../components/features'
import ImageContent from '../components/image-content'
//import Instagram from '../components/instagram'
import PagesRepeaterDouble from '../components/pages-repeater-double'
import PagesRepeaterSlider from '../components/pages-repeater-slider'
import VideoContent from '../components/video-content'
import CompareTable from '../components/compare-table'
import Clients from '../components/clients'
import Studio from '../components/studio'


const renderBlock = (param, el, i, func=null) => {
  let block = {
    'page_Acf_ContentBlocks_VideoBanner' : (el, i) => <VideoBanner key={i} {...el} />,
    'page_Acf_ContentBlocks_PagesRepeater' : (el, i) => <PagesRepeater key={i} {...el} />,
    'page_Acf_ContentBlocks_ProjectSlider' : (el, i) => <ProjectSlider key={i} {...el} setModal={func} />,
    'page_Acf_ContentBlocks_Banner' : (el, i) => <Banner key={i} {...el} />,
    'page_Acf_ContentBlocks_ContactForm' : (el, i) => <ContactForm key={i} {...el} />,
    'page_Acf_ContentBlocks_ContactMap' : (el, i) => <ContactMap key={i} {...el} />,
    'page_Acf_ContentBlocks_ProjectGallery' : (el, i) => <ProjectGallery key={i} {...el} setModal={func} />,
    'page_Acf_ContentBlocks_BannerTitle': (el, i) => <BannerTitle key={i} {...el} />,
    'page_Acf_ContentBlocks_Features': (el, i) => <Features key={i} {...el} />,
    'page_Acf_ContentBlocks_ImageContent': (el,i) => <ImageContent key={i} {...el} />,
    'page_Acf_ContentBlocks_Clients': (el,i) => <Clients key={i} {...el} />,
    'page_Acf_ContentBlocks_Studio': (el,i) => <Studio key={i} {...el} />,
    //'WordPressAcf_instagram': (el, i) => <Instagram key={i} {...el} />,
    'page_Acf_ContentBlocks_PagesRepeaterDouble': (el, i) => <PagesRepeaterDouble key={i} {...el} />,
    'page_Acf_ContentBlocks_PagesRepeaterSlider': (el, i) => <PagesRepeaterSlider key={i} {...el} />,
    'page_Acf_ContentBlocks_CompareTable': (el, i) => <CompareTable key={i} {...el} />,
    'page_Acf_ContentBlocks_VideoContent': (el, i) => <VideoContent key={i} {...el} />,
  }[param]

  if (!block) return null

  return block(el, i)
}

export default renderBlock
