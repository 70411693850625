import React, { Component } from 'react'


class ImageContent extends Component {

  render() {
    const { picture, heading, content} = this.props

    return (
      <>
        <section className="image-content">
          <div className="image-content__inner">
            <div className="image-content__image">
              <img src={picture.localFile.childImageSharp.original.src} alt={picture.alt_text} />
            </div>
            <div className="image-content__info">
              <h6>{heading}</h6>
              <p dangerouslySetInnerHTML={{__html: content}} />
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default ImageContent
