import React, { Component } from 'react'


class Banner extends Component {

  render() {
    const { bannerImage } = this.props

    return(
      <div className='full-section fp-auto-height'>
        <section className="banner">
          <div className="banner__inner">
            <img src={ bannerImage.localFile.childImageSharp.original.src} alt={bannerImage.altText} />
          </div>
        </section>
      </div>
    )
  }
}

export default Banner
