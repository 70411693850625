import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class BannerTitle extends Component {

  state = {
    isMobile: false
  }

  throttledHandleWindowResize = () => {
    this.setState({ isMobile: window.innerWidth < 900 })
  }

  componentDidMount() {
    this.throttledHandleWindowResize()
    window.addEventListener('resize', this.throttledHandleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledHandleWindowResize);
  }


  _renderVideo() {
    let { video, banner, mobileVideo } = this.props
    let { isMobile } = this.state

    if (video.indexOf('vimeo') !== -1) {
      video = video.split('vimeo.com/')[1]
      if (video.indexOf('/') !== -1) video = video.split('/')[0]
      return <iframe src={`https://player.vimeo.com/video/${video}?autoplay=1&loop=1&autopause=0`} frameBorder="0" allow="autoplay; fullscreen" allow='autoplay'></iframe>
    }

    return <video autoPlay muted playsInline loop src={mobileVideo && isMobile ? mobileVideo : video }  poster={banner.localFile.childImageSharp.original.src} />
  }

  render() {
    const { heading, subheading } = this.props

    return (
      <section className='banner-title'>
        <div className='banner-title__inner'>
          <div className='title-content'>
            <Fade>
              <h1>{heading}</h1>
              <p dangerouslySetInnerHTML={{__html: subheading }} />
            </Fade>
          </div>
        </div>
        <div className='banner-title__video'>
          { this._renderVideo() }
        </div>
      </section>
    )
  }
}

export default BannerTitle
