import React, { Component } from 'react'

import Link from '../utils/link'

class PagesRepeater extends Component {


  state = {
    isMobile: false
  }

  throttledHandleWindowResize = () => {
    this.setState({ isMobile: window.innerWidth < 900 })
  }

  componentDidMount() {
    this.throttledHandleWindowResize()
    window.addEventListener('resize', this.throttledHandleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledHandleWindowResize);
  }

  _renderPages = (el, i) => {
    let imageSrc = el.picture.localFile.childImageSharp.original.src
    let { isMobile } = this.state

    return (
      <div className='full-section' key={i}>
        <div className='repeater__item'>
          <picture>
            <video autoPlay muted playsInline loop src={el.mobileVideo && isMobile ? el.mobileVideo : el.video }  poster={imageSrc} data-keepplaying />
          </picture>
          <div className='content'>
            <div className='content__inner'>
              { el.tagline && <h5>{ el.tagline }</h5> }
              { el.title && <h3>{ el.title }</h3> }
              <p dangerouslySetInnerHTML={{__html: el.content}}/>
              <a className='btn btn--fancy' href={el.buttonLink}><span>{ el.buttonText }</span></a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { repeaterItem } = this.props

    return (
      <>
        <section ref='cont' className='pages-repeater' />
        { repeaterItem.map(this._renderPages) }
      </>
    )
  }
}


export default PagesRepeater
