import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Slider from 'react-slick'

import Link from '../utils/link'

class PagesRepeaterSlider extends Component {

  settings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    cssEase: 'ease',
    touchMove: true,
    pauseOnHover: true,
    pauseOnDotsHover: true,
    pauseOnFocus: true,
    focusOnSelect: true,
    swipe: () => { this.slider.slickPause() },
  }


  componentDidMount() {
    ReactDOM.findDOMNode(this.slider).addEventListener('wheel', this._handleWheel)
  }

  componentWillUnmount() {
    ReactDOM.findDOMNode(this.slider).removeEventListener('wheel', this._handleWheel)
  }

  _handleWheel = (event) => {
    event.preventDefault()
    event.deltaY > 0 ? this.slider.slickNext() : this.slider.slickPrev()
  }

  renderLink = (el) => {

    let props = {
      className: "btn btn--fancy",
      href: '#contact',
      onClick: (e) => {
        if (!window.fullpage_api) return
        e.preventDefault()
        let target = document.querySelector('.contact-form').closest('.full-section')

        let list = document.querySelectorAll('.full-section')
        for (let i = 0, l = list.length; i++ < l;) {
          if (list[i] === target)
            window.fullpage_api.moveTo(i + 1)
        }
      }
    }

    return (
      <a {...props}><span>{ el.buttonText }</span></a>
    )
  }

  _renderPages = (el, i) => {

    return (
      <div className='full-section' key={i}>
        <div className='repeater__item repeater__item--slider'>
          <Slider className='slider' ref={slider => (this.slider = slider)} {...this.settings}>
            { el.slides && el.slides.map((element, j) => {
              let props = {
                className: `slider__item`,
                ref: `q_${j}`,
              }
              return (
                <div {...props} key={j}>
                  <img src={element.picture.localFile.childImageSharp.original.src} alt={element.picture.altText}/>
                </div>
              )
            })
            }
          </Slider>
          <div className='content'>
            <div className='content__inner'>
              { el.tagline && <h5>{ el.tagline }</h5> }
              { el.title && <h3>{ el.title }</h3> }
              <div dangerouslySetInnerHTML={{__html: el.content }} />
              { this.renderLink(el) }
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { repeaterItem } = this.props

    return (
      <>
        { repeaterItem.map(this._renderPages) }
      </>
    )
  }
}


export default PagesRepeaterSlider
