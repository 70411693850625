import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'



class ProjectGallery extends Component {

  static defaultProps = {
    heading: '',
    items: [],
    noPaddingTop: false,
  }

  renderModal = (i) => {
    const { items } = this.props
    let current = items[i]
    let source = current && current.picture.localFile.childImageSharp.original.src
    let video = this._renderVideo(current.videoUrl)

    return (
      <>
        { video || <img src={source} alt={current.picture.altText} /> }
        <div className="information">
          <div className="details" dangerouslySetInnerHTML={{__html: current.info }} />
        </div>
      </>
    )
  }

  _renderSlider = (el, i) => {
    let props = {
      key: i,
      className: `gallery__item`,
      ref: `q_${i}`,
      onClick: () => this.props.setModal(this.renderModal(i))
    }
    return (
      <div {...props}>
        <picture>
          <img src={el.picture.localFile.childImageSharp.original.src} alt={el.picture.altText} />
        </picture>
        <p>{ el.title }</p>
      </div>
    )
  }

  _renderVideo(video) {
    if (video.indexOf('iframe') !== -1) {
      return <div dangerouslySetInnerHTML={{__html: video }} />
    }
    if (video.indexOf('vimeo') !== -1) {
      video = video.split('vimeo.com/')[1]
      let videoArray = [video]
      let hidden = ''
      let url = ''
      if (video.indexOf('/') !== -1) { 
        videoArray = video.split('/')
        hidden = videoArray[1]
      }
      url = `https://player.vimeo.com/video/${videoArray[0]}?autoplay=1&loop=1&autopause=0`
      if (hidden !== '') url = `https://player.vimeo.com/video/${videoArray[0]}?h=${hidden}&autoplay=1&loop=1&autopause=0`
      return <iframe src={url} frameBorder="0" allow="autoplay; fullscreen" allow='autoplay'></iframe>
    }
    return null
  }

  render() {
    const { items, heading, noPaddingTop } = this.props

    return (
      <>
        <div className='full-section'>
          <section className={`project-gallery${noPaddingTop ? ' project-gallery--npt' : ''}`}>
            <div className='project-gallery__inner'>
              <Fade>
                <h3>{ heading }</h3>
                <div className='gallery'>
                  { items && items.map(this._renderSlider) }
                </div>
              </Fade>
            </div>
          </section>
        </div>
      </>
    )
  }
}

export default ProjectGallery
