import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import Link from '../utils/link'

class CompareTable extends Component {

  render() {
    const { subtitle, title, items } = this.props

    return (
      <>
        <section className='compare-table' id='compare'>
          <div className='compare-table__inner'>
            <h3 dangerouslySetInnerHTML={{ __html: subtitle }} />
            <h2 dangerouslySetInnerHTML={{ __html: title }} />
            <div className='compare-table__items'>
              { items.map((el, i) => (
                <div className='compare-table__item' key={i}>
                  { el.image && <img src={el.image?.localFile?.childImageSharp.original.src} alt={el.product} /> }
                  <div className='compare-table__sticky'>
                    <h4>{el.product}</h4>
                    <Link to={el.button.url} className='btn btn--fancy'><span>{el.button.title}</span></Link>
                  </div>
                  { el.section?.length > 0 && 
                    <div className='compare-table__sections'>
                      {el.section.map((section, s) => (
                        <div className='compare-table__features' key={s}>
                          <h5>{ i === 0 ? section.title : ''}</h5>
                          { section.items?.length > 0 &&
                            <ul>
                              {section.items.map((feature, f) => (
                                <li key={f}>
                                  <span>{feature.label}</span>
                                  <span>{feature.value}</span>
                                </li>
                              ))}
                            </ul>
                          }
                        </div>
                      ))}
                    </div>
                  }
                </div>
              )) }
            </div>
            <div className='compare-table__mobile'>
              <div className='compare-table__mobile-images'>
                { items.map((el, i) => (
                  <div className='compare-table__mobile-image' key={i}>
                    { el.image && <img src={el.image?.localFile?.childImageSharp.original.src} alt={el.product} /> }
                  </div>
                )) }
              </div>
              <div className='compare-table__mobile-titles'>
                { items.map((el, i) => (
                  <div className='compare-table__mobile-title' key={i}>
                    <Link to={el.button.url}>
                      {(() => {
                        switch (el.product) {
                          case 'BOLT Cinebot':
                            return 'BOLT Cinebot';
                          case 'BOLT Jr+ Cinebot':
                            return 'BOLT Jr+';
                          case 'Cinebot Mini':
                            return 'Cinebot Mini';
                          default:
                            return el.product;
                        }
                      })()}
                    </Link>
                  </div>
                )) }
              </div>
              { items[0].section?.length > 0 && 
                <div className='compare-table__sections'>
                  {items[0].section.map((section, s) => (
                    <div className='compare-table__mobile-features' key={s}>
                      <h5>{ s === 0 ? section.title : ''}</h5>
                      <div className='compare-table__mobile-feature'>
                        { section.items?.length > 0 &&
                          <ul>
                            {section.items.map((feature, f) => (
                              <li key={f}>{feature.label}</li>
                            ))}
                          </ul>
                        }
                        { items?.length > 0 && items.map((product, p) => (
                          <ul key={p}>
                            {product.section[s].items.map((feature, f) => (
                              <li key={f}>{feature.value}</li>
                            ))}
                          </ul>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              }
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default CompareTable