import React, { Component } from 'react'

class VideoContent extends Component {

  _renderVideo(video) {
    if (video.indexOf('iframe') !== -1) {
      return <div dangerouslySetInnerHTML={{__html: video }} />
    }
    if (video.indexOf('vimeo') !== -1) {
      video = video.split('vimeo.com/')[1]
      if (video.indexOf('/') !== -1) video = video.split('/')[0]
      return <iframe src={`https://player.vimeo.com/video/${video}`} frameBorder="0" allow="autoplay; fullscreen" allow='autoplay'></iframe>
    }
    return null
  }

  render() {
    let video = this._renderVideo(this.props.video)

    return (
      <section className='video-content'>
        <div className='video-content__inner'>
          <h1 className="video-content__inner__title">{ this.props.title }</h1>
          <div className="video-content__video">{video}</div>
          <div className="video-content__inner__description" dangerouslySetInnerHTML={{__html: this.props.content }} />
        </div>
      </section>
    )
  }
}

VideoContent.defaultProps = {
  title: '',
  content: '',
  video: '',
}

export default VideoContent
