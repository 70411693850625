import React, { Component } from 'react'
import Link from '../utils/link'


import Insta from '../assets/images/instagram.svg'
import Facebook from '../assets/images/facebook.svg'
import Youtube from '../assets/images/youtube.svg'
import IMDB from '../assets/images/imdb.png'
//import Twitter from '../assets/images/twitter.svg'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}


class ContactForm extends Component {
  state = {
    modal: false,
    form: {
      name: '',
      email: '',
      phone: '',
      enquiry: '',
      page: '',
    }
  }

  componentDidMount() {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        page: window.location.pathname
      }
    }))
  }

  handleChange = e => {
    let { form } = this.state
    form[e.target.name] = e.target.value
    this.setState({ form })
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.refs.form.checkValidity()) return false

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state.form
      })
    })
    .then(() => this.setState({result: 'success'}))
    .catch(error => this.setState({result: 'fail', msg: error}));
  }


  render() {
    let formProps = {
      ref: 'form',
      name: 'submission',
      className: 'contact',
      onSubmit: this.handleSubmit,
      'data-netlify': 'true',
      'data-netlify-honeypot': 'bot-field',
    }
    let { heading } = this.props

    if (this.state.result === 'success') {
      return (
        <section className='contact-form full-section' data-anchor='contact-form'>
          <div className='contact-form__inner'>
            <div className="success-thanks"><h2>Thanks for getting in contact with us.</h2><br /> A member of our team will be in touch soon.</div>
          </div>
        </section>
      )
    }

    return (
      <div className='full-section'>
        <section className='contact-form' id="contact">
          <div className='contact-form__inner'>
              <div className='info'>
                <h3>{heading}</h3>
                <ul>
                  <li><Link to='https://www.instagram.com/robotface_au/'><img src={Insta} alt='Instagram' /></Link></li>
                  <li><Link to='https://www.facebook.com/robotface/'><img src={Facebook} alt='Facebook' /></Link></li>
                  <li><Link to='https://www.youtube.com/channel/UChf0lMgF9wPSGOqcHwg5Uew'><img src={Youtube} alt='Youtube' /></Link></li>
                  <li><Link to='https://www.imdb.com/name/nm14741283/'><img src={IMDB} width='25px' alt='IMDb' /></Link></li>
                </ul>
              </div>
              <form {...formProps}>
                <input type='text' name='name'  placeholder='Your Name' onChange={this.handleChange} required/>
                <input type='email' name='email'  placeholder='Email' onChange={this.handleChange} required/>
                <input type='text' name='phone' placeholder='Phone Number' onChange={this.handleChange} required/>
                <input type='text' name='enquiry' placeholder='Enquiry' onChange={this.handleChange} required/>
                <input type="hidden" name="page" value={this.state.form.page} onChange={this.handleChange} />
                <input type="hidden" name="form-name" value="contact-form" />
                <button className='btn btn--fancy' type='submit'>Send</button>
              </form>
          </div>
        </section>
      </div>
    )
  }
}


export default ContactForm
